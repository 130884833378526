import {has, mapValues, pickBy, set} from 'lodash-es';

export function useQuery<T extends Record<string, string | number | object> = Record<string, string | number | object>>() {
  const route = useRoute();
  function getParsedQuery (): Partial<T> {
    return pickBy(route.query ? route.query : {}, (value: string | string[]) => {
      return value !== '';
    }) as any as T;
  };

  //TODO: useQuery -> ssr ? albo
  function getNonArrayQuery  (): Partial<T> {
    return mapValues(getParsedQuery(), (value: string | string[]) => {
      return Array.isArray(value)
        ? value[0]
        : !isNaN(value as any)
          ? parseFloat(value)
          : value;
    }) as any as T;
  };

  const query = getNonArrayQuery()

  const getParsedQueryDeep = (model: T, nestedProp?: string): T => {
    const queryParams = getParsedQuery();
    const nestedPropPath = nestedProp ? `${nestedProp}.` : '';
    Object.keys(queryParams).map(param => {
      if (!has(model, `${nestedPropPath}${param}`)){
        return;
      }

      set(model, `${nestedPropPath}${param}`, queryParams[param]);
    });

    return model;
  };

  return {
    getParsedQuery,
    /**
     * @deprecated The method should not be used, use default getQuery
     */
    getNonArrayQuery,
    getParsedQueryDeep,
    // Default value
    getQuery: getNonArrayQuery,
    query
  };
};
